import {
  Box,
  Container,
  CssBaseline,
  Paper,
  FormControlLabel,
  Switch,
  Autocomplete,
  TextField,
  Chip,
  Button,
  InputAdornment,
  IconButton,
  Stack,
  Typography,
  Divider,
  Alert,
  CircularProgress,
  Modal,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import Carousel from "react-material-ui-carousel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import PostService from "../services/PostService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { UserContext } from "../root";
import jwtDecode from "jwt-decode";
import clsx from "clsx";
import { styled, Theme } from "@mui/system";
import { ShowTopUpModal } from "../common/ShowTopUpModal";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  zIndex: 99,
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function PostAdFormStep2(props) {
  const [openModel, setOpenModel] = useState(false);
  // const handleOpenModel = () => setOpen(true);
  const handleCloseModel = () => setOpenModel(false);
  const [modelError, setModelError] = useState([]);

  const { countrySate, categories } = useContext(UserContext);
  const [setcountryC, setSetCountryC] = countrySate;
  const token = localStorage.getItem("user");
  const decoded = token ? jwtDecode(token) : null;
  const totalBalance = decoded.coinsLeft;
  // Use the useLocation hook to access the location state
  const location = useLocation();
  const { formDataNew, selectedSubCategoryNew, selectedFilesIMage } =
    location.state || {};
  // console.log("formDataNew",formDataNew);
  // console.log("selectedFilesIMage",selectedFilesIMage);

  const navigate = useNavigate(); // Use useNavigate instead of useHistory
  const [loading, setLoading] = useState(true);
  const [country, setCountry] = useState([]);
  const initialValue = setcountryC.map((name) => ({ name }));
  const [value, setValue] = useState([initialValue]);
  // const [amountToPay, setAmountToPay] = useState(0);
  console.log("value", value);
  // const [inputValue, setInputValue] = useState("");
  const [isSwitchChecked, setIsSwitchChecked] = useState(true); // Initialize the switch state
  const [couponCode, setCouponCode] = useState(""); // State to store the coupon code
  const [couponApplied, setCouponApplied] = useState(false); // State to track if coupon is applied
  const [productSummary, setProductSummary] = useState([]);
  console.log("product-summary------------------", productSummary);
  const [FeaturedAmount, setFeaturedAmount] = useState(0);
  const [CouponAmount, setCouponAmount] = useState(0);
  const [CouponPercent, setCouponpercent] = useState(0);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getCountryList = () => {
    PostService.getCountryList()
      .then((response) => {
        setCountry(response.data.data.country);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  // console.log("Country List", country);
  const getProductSummary = () => {
    // console.log(selectedCategory.id);
    // console.log(isSwitchChecked);
    PostService.postProductSummary(selectedSubCategoryNew.id, isSwitchChecked)
      .then((response) => {
        setProductSummary(response.data.data);
        setLoading(false);
        // console.log("Product Summary", response.data.data);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    getProductSummary();
    getCountryList();
  }, [value]);

  useEffect(() => {
    if (setcountryC.length > 0) {
      // Update the value state with only the first item of setcountryC
      setValue([{ name: setcountryC[0] }]);
    }
  }, [setcountryC]); // Run this effect whenever setcountryC changes

  useEffect(() => {
    if (isSwitchChecked) {
      const amount =
        (value.length - 1) * productSummary?.Summary?.FeaturedAdpoints;
      setFeaturedAmount(amount);

      const amount1 =
        (productSummary?.Summary?.CostforAdpost +
          (productSummary?.Summary?.FeaturedAdpoints ?? 0) +
          amount) *
        (CouponPercent / 100);
      // console.log("amount",amount1)

      setCouponAmount(amount1);
    } else {
      const amount1 =
        (productSummary?.Summary?.CostforAdpost +
          (productSummary?.Summary?.FeaturedAdpoints ?? 0)) *
        (CouponPercent / 100);
      // console.log("amount",amount1)
      setFeaturedAmount(0);
      setCouponAmount(amount1);
    }
  }, [value, productSummary]);

  // Function to handle the coupon code input change
  const handleCouponCodeChange = (event) => {
    setCouponCode(event.target.value);
  };

  // Function to handle applying and removing the coupon
  const handleCouponAction = () => {
    if (couponApplied) {
      // Coupon is applied, remove it
      setCouponApplied(false);
      setCouponCode(""); // Clear the coupon code
    } else {
      // Coupon is not applied, apply it (you can add your validation logic here)
      // For demonstration purposes, we'll simply check if the coupon code is not empty
      if (couponCode.trim() !== "") {
        // Apply the coupon code
        setLoading(true);
        PostService.postCouponCode(couponCode)
          .then((response) => {
            if (response.data.data) {
              // Coupon code is valid, apply it
              setCouponApplied(true);
              setCouponpercent(response.data.data.discount);
              Amountreflectusingcoupon(response.data.data.discount);
              toast.success("Success : Coupon Applied Successfully", {
                position: toast.POSITION.TOP_RIGHT,
              });
              setLoading(false);
            } else {
              // Coupon code is not valid, handle the error as needed
              // For example, display an error message to the user
              console.log("Invalid coupon code");
              setLoading(false);
            }
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              // If the response contains a message, display it as the error message
              toast.error(`${error.response.data.message}`, {
                position: toast.POSITION.TOP_RIGHT,
              });
              setLoading(false);
            }
            // else {
            //   toast.error("Something went wrong!", {
            //     position: toast.POSITION.TOP_RIGHT,
            //   });
            // }

            console.log(error);
            setLoading(false);
          });
      }
    }
  };

  const Amountreflectusingcoupon = (discount) => {
    if (couponApplied) {
      const totalCostWithoutFeatured =
        productSummary?.Summary?.CostforAdpost || 0;
      let couponAmount = 0;

      if (isSwitchChecked) {
        // Ads are featured, include FeaturedAmount
        couponAmount =
          (productSummary?.Summary?.CostforAdpost +
            (productSummary?.Summary?.FeaturedAdpoints ?? 0) +
            FeaturedAmount) *
          (discount / 100);
      } else {
        // Ads are not featured, calculate based on total cost without FeaturedAmount
        couponAmount = totalCostWithoutFeatured * (discount / 100);
      }
      setCouponAmount(couponAmount);
    } else {
      // Coupon is not applied, reset CouponAmount to 0
      setCouponAmount(0);
    }
  };

  useEffect(() => {
    Amountreflectusingcoupon(CouponPercent);
  }, [value, couponApplied, CouponPercent, isSwitchChecked]);

  const uploadPOST = async () => {
    debugger;
    // try {
    // Define an array to store the selected country names
    const selectedCountryNames = value.map((option) => option.name);
    let formData = new FormData();

    // Append the properties from formDataNew to formData
    for (const key in formDataNew) {
      if (formDataNew.hasOwnProperty(key)) {
        formData.append(key, formDataNew[key]);
      }
    }
    // Append the 'files' field with the selectedFilesIMage
    for (const file of selectedFilesIMage) {
      formData.append("files", file);
    }
    formData.append("featured", isSwitchChecked);
    formData.append("countryList", selectedCountryNames);
    formData.append(
      "totalAmount",
      isSwitchChecked
        ? productSummary?.Summary?.CostforAdpost +
            (productSummary?.Summary?.FeaturedAdpoints ?? 0) +
            FeaturedAmount -
            productSummary?.Summary?.Discount -
            CouponAmount
        : productSummary?.Summary?.CostforAdpost -
            productSummary?.Summary?.Discount -
            CouponAmount
    );
    formData.append("costCountryWise", FeaturedAmount);
    formData.append("couponcost", CouponAmount);
    formData.append("couponCode", couponCode);
    // Create an empty JavaScript object to store the formData contents
    const formDataObject = {};

    // Iterate over the formData object and populate the formDataObject
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });

    // // Now you can stringify formDataObject
    console.log(JSON.stringify(formDataObject));
    setLoading(true);
    PostService.postAd(formData)
      .then((response) => {
        // setUser({ ...user });
        toast.success("Success : ADs Posted Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate("/thank-you");
        console.log(response.data);
        setLoading(false);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          // If the response contains a message, display it as the error message
          toast.error(`${error.response.data.message}`, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        }
        setModelError(error.message);
        setOpenModel(true);
        setLoading(false);
        console.log(error);
      });
    // } catch (error) {
    //   // Handle any errors that occur during the execution of the function
    //   console.error("An error occurred:", error);
    //   setLoading(false);
    // }
  };

  const handlePayNow = () => {
    // let actualAmount = isSwitchChecked
    //   ? productSummary?.Summary?.CostforAdpost +
    //     (productSummary?.Summary?.FeaturedAdpoints ?? 0) +
    //     FeaturedAmount -
    //     productSummary?.Summary?.Discount -
    //     CouponAmount
    //   : productSummary?.Summary?.CostforAdpost -
    //     productSummary?.Summary?.Discount -
    //     CouponAmount;
    // if (productSummary.coinsLeft < actualAmount) {
    //   toast.warn("You have not enough balance to post ad! Kindly add coins.", {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    //   handleOpen();
    // } else {
    uploadPOST();
    // }
  };

  // useEffect(() => {
  //   let amount = isSwitchChecked
  //     ? productSummary?.Summary?.CostforAdpost +
  //       (productSummary?.Summary?.FeaturedAdpoints ?? 0) +
  //       FeaturedAmount -
  //       productSummary?.Summary?.Discount -
  //       CouponAmount
  //     : productSummary?.Summary?.CostforAdpost -
  //       productSummary?.Summary?.Discount -
  //       CouponAmount

  //       setAmountToPay(amount)

  // }, [isSwitchChecked,productSummary,amountToPay])

  return (
    <>
      <Container component="main" maxWidth="md" sx={{ width: 750 }}>
        <ShowTopUpModal
          handleClose={handleClose}
          open={open}
          getProductSummary={getProductSummary}
          balance={productSummary.coinsLeft}
        />
        <CssBaseline />

        {loading ? (
          <div className="loading-spinner-overlay">
            <div className="loading-spinner">
              <CircularProgress size={60} /> {/* Customize size as needed */}
            </div>
          </div>
        ) : (
          <Box
            sx={{
              marginTop: 1,
              display: "flex",
              flexDirection: "column",
              boxShadow:
                "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
              borderRadius: "15px",
              background: "#fff",
            }}
          >
            {/* <Button variant="outlined" onClick={() => navigate(-1)}>
          Go Back
        </Button> */}
            <Example />
            <Box
              sx={{
                marginTop: 1,
                padding: 2,
              }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={isSwitchChecked}
                    onChange={() => {
                      setIsSwitchChecked(!isSwitchChecked);
                    }}
                  />
                }
                label={
                  <strong>Do you want to post this Ad as featured?</strong>
                }
                id="featuredswitch"
              />
              <Typography sx={{ mb: 3, fontSize: "13px" }}>
                Displaying Ad as featured helps you to list your items in
                Recommendations on Home page and product listing page. It also
                displays featured ads at the top of the search lists but will be
                considered only for 30 days after that It will be treated as
                general post. You can post your ads in other nearest countries
                too if you want but It will cost you additional coins for that.
              </Typography>
              {/* <Alert icon={false} severity="success">
            Displaying Ad as featured helps you to list your items in Recommendations on Home page and product listing page. It also displays featured ads at the top of the search lists but will be considered only for 30 days after that It will be treated as general post. You can post your ads in other nearest countries too if you want but It will cost you additional coins for that.
          </Alert> */}
              {isSwitchChecked && ( // Show Autocomplete section only if the Switch is checked
                <div>
                  <Autocomplete
                    multiple
                    id="countrylist"
                    value={value}
                    onChange={(event, newValue) => {
                      // Check if the first element is in the newValue and preserve it
                      if (
                        value.length > 0 &&
                        !newValue.find(
                          (option) => option.name === value[0].name
                        )
                      ) {
                        newValue = [value[0], ...newValue];
                      }

                      // Remove duplicates from newValue
                      const uniqueNewValue = Array.from(
                        new Set(newValue.map((option) => option.name))
                      );

                      // Ensure that only unique countries are selected
                      setValue(uniqueNewValue.map((name) => ({ name })));
                    }}
                    options={country}
                    getOptionLabel={(option) => option?.name}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          label={option?.name}
                          {...getTagProps({ index })}
                          disabled={index === 0}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Please Select Countries"
                        placeholder="Choose Country"
                        fullWidth
                        id="choosecountry"
                      />
                    )}
                  />
                </div>
              )}

              <TextField
                label="Coupon Code"
                variant="outlined"
                margin="normal"
                id="couponcode"
                fullWidth
                value={couponCode}
                onChange={handleCouponCodeChange}
                disabled={couponApplied} // Disable the TextField when coupon is applied
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        color={couponApplied ? "secondary" : "primary"}
                        aria-label={
                          couponApplied ? "remove coupon" : "apply coupon"
                        }
                        onClick={handleCouponAction}
                        id="applycoupon"
                      >
                        {couponApplied ? (
                          <HighlightOffIcon />
                        ) : (
                          // <CheckCircleOutlineIcon />
                          <Typography>Apply</Typography>
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Typography component="h5" variant="h6">
                  Transaction Summary
                </Typography>
                <Typography component="h5" variant="h6">
                  Coins
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Typography variant="button" display="block" gutterBottom>
                  Cost As Per Selected Category
                </Typography>
                <Typography variant="button" display="block" gutterBottom>
                  {productSummary?.Summary?.CostforAdpost}
                </Typography>
              </Stack>
              {isSwitchChecked && (
                <>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                  >
                    <Typography variant="button" display="block" gutterBottom>
                      Featured Ads Coins
                    </Typography>
                    <Typography variant="button" display="block" gutterBottom>
                      {productSummary?.Summary?.FeaturedAdpoints ?? 0}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                  >
                    <Typography variant="button" display="block" gutterBottom>
                      Ads Visibility Cost Country Wise
                    </Typography>
                    <Typography variant="button" display="block" gutterBottom>
                      {FeaturedAmount}
                    </Typography>
                  </Stack>
                </>
              )}

              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Typography
                  variant="button"
                  display="block"
                  gutterBottom
                  sx={{ color: "red" }}
                >
                  Discount
                </Typography>
                <Typography
                  variant="button"
                  display="block"
                  gutterBottom
                  sx={{ color: "red" }}
                >
                  {productSummary?.Summary?.Discount}
                </Typography>
              </Stack>
              <Divider
                sx={{
                  borderTop: "3px solid black", // Adjust the border style and thickness as needed
                }}
              />
              {couponApplied && (
                <>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="button"
                      display="block"
                      gutterBottom
                      sx={{ color: "#52b202", fontWeight: 600 }}
                    >
                      Coupon Discount
                    </Typography>
                    <Typography
                      variant="button"
                      display="block"
                      gutterBottom
                      sx={{ color: "#52b202", fontWeight: 600 }}
                    >
                      {CouponAmount}
                    </Typography>
                  </Stack>
                </>
              )}

              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Typography
                  variant="button"
                  display="block"
                  gutterBottom
                  sx={{ fontWeight: 600 }}
                >
                  Total
                </Typography>
                <Typography
                  variant="button"
                  display="block"
                  gutterBottom
                  sx={{ fontWeight: 600 }}
                >
                  {isSwitchChecked
                    ? productSummary?.Summary?.CostforAdpost +
                      (productSummary?.Summary?.FeaturedAdpoints ?? 0) +
                      FeaturedAmount -
                      productSummary?.Summary?.Discount -
                      CouponAmount
                    : productSummary?.Summary?.CostforAdpost -
                      productSummary?.Summary?.Discount -
                      CouponAmount}
                </Typography>
              </Stack>
              <Button
                variant="contained"
                color="warning"
                onClick={handlePayNow}
                id="paynow"
              >
                Pay Now
              </Button>
            </Box>
          </Box>
        )}
      </Container>
      <Modal
        open={openModel}
        onClose={handleCloseModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {modelError}
          </Typography>
        </Box>
      </Modal>
    </>
  );
}

function Example(props) {
  var items = [
    {
      name: "Buy and Sell with Ease",
      description: "Buy and Sell with Ease",
      imageUrl: "img/post-slider-1.png", // Specify the image URL here
    },
    {
      name: "Sell in Nearby Countries",
      description: "Sell in Nearby Countries",
      imageUrl: "img/post-slider-3.png", // Specify the image URL here
    },
    {
      name: "Premium As Posts",
      description: "Premium As Posts",
      imageUrl: "img/post-slider-2.png", // Specify the image URL here
    },
    {
      name: "Refer & Earn",
      description: "Sell in Nearby Countries",
      imageUrl: "img/post-slider-4.png", // Specify the image URL here
    },
  ];

  return (
    <Carousel>
      {items.map((item, i) => (
        <Item key={i} item={item} />
      ))}
    </Carousel>
  );
}

function Item(props) {
  return (
    <Paper>
      <img
        src={props.item.imageUrl}
        alt={props.item.name}
        height={250}
        width="100%"
      />{" "}
      {/* Display the image */}
    </Paper>
  );
}
